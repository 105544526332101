import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';
import { HomeFirstScreen } from 'components/HomeFirstScreen/HomeFirstScreen';
import { NewsList } from 'components/NewsList/NewsList';
import { NewsCards } from 'components/NewsCards/NewsCards';
import { InfiniteLine } from 'components/InfiniteLine/InfiniteLine';
import { Ecology } from 'components/Ecology/Ecology';
import { Cites } from 'components/Cites/Cites';
import { Instructions } from 'components/Instructions/Instructions';

import {
    getHomepage,
    getHomepageIdle,
    getPosts,
    getPostsIdle,
    getCompilation,
} from 'store/ducks/homepage/actions';
import { StateStatus } from 'types/models';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ViewReduxType } from '../../store/ducks/homepage/types';
import { EcologyReduxType } from '../../store/ducks/homepage/types';
import { PostSelectorType } from '../../store/ducks/post/types';
import { Loading } from '../../components/UI/Loading/Loading';
import { domainName } from '../../config';

import { Compilation } from 'components/Compilation/Compilation';

const Home = () => {
    const [browser, setBrowser] = useState(true);

    const dispatch = useDispatch();

    const { status, postsAllNumber, posts, statusPosts, view } = useSelector(
        (state: {
            homepage: {
                status: StateStatus;
                postsAllNumber: number;
                posts: PostSelectorType[];
                statusPosts: StateStatus;
                view: ViewReduxType;
                compilation2: EcologyReduxType;
            };
        }) => state.homepage
    );

    useEffect(() => {
        if (statusPosts === 'IDLE') {
            console.log('posts22: ', posts);
            dispatch(getPosts({ offset: posts.length }));
        }
    }, []);

    useEffect(() => {
        if (status === 'IDLE') {
            dispatch(getHomepage());
        }
    }, []);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth < 1024) {
                setBrowser(false);
            } else {
                setBrowser(true);
            }
            const resizeThrottler = () => {
                if (window.innerWidth < 1024) {
                    setBrowser(false);
                } else {
                    setBrowser(true);
                }
            };

            window.addEventListener('resize', resizeThrottler, false);
        }
    }, []);

    function getLoadMorePosts() {
        console.log('posts33: ', posts);
        dispatch(
            getPosts({
                offset: posts.length,
            })
        );
    }

    if (status === 'LOADING') return <Loading />;

    return (
        <>
            <Helmet>
                <title>Рядом — Как сделать лучше всё вокруг</title>
                <meta
                    property="og:title"
                    content={`Рядом — Как сделать лучше всё вокруг`}
                />
                <meta
                    property="twitter:title"
                    content={`Рядом — Как сделать лучше всё вокруг`}
                />
                <meta
                    name="description"
                    content={`Журнал Группы «Эталон» о том, что такое классный город в XXI веке, как в нем жить и как сделать его лучше.`}
                />
                <meta
                    property="og:description"
                    content={`Журнал Группы «Эталон» о том, что такое классный город в XXI веке, как в нем жить и как сделать его лучше.`}
                />
                <meta
                    property="twitter:description"
                    content={`Журнал Группы «Эталон» о том, что такое классный город в XXI веке, как в нем жить и как сделать его лучше.`}
                />
                <meta
                    property="og:image"
                    content={`https://r${domainName}/img/ryadom_fb_share.jpg`}
                />
                <meta property="og:url" content={`https://${domainName}/`} />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <main className="page">
                <HomeFirstScreen />
                <section className="news page__news">
                    <div className="news__container">
                        <div></div>
                        <div></div>
                        <div className="news__content">
                            {browser && (
                                <div className="news__wrap">
                                    <Link
                                        to="/news"
                                        className="news-list__link"
                                    >
                                        <h2 className="news__title">Новости</h2>
                                    </Link>

                                    <NewsList />
                                </div>
                            )}

                            <NewsCards />
                        </div>
                        {postsAllNumber !== posts.length && (
                            <button
                                className="news-cards__more"
                                onClick={getLoadMorePosts}
                            >
                                {' '}
                                ещё{' '}
                            </button>
                        )}
                    </div>
                </section>

                {view && view.acf && view.acf.compilation &&
                    view.acf.compilation.map((e: any) => (
                        <>
                            <Compilation content={e} />
                        </>
                    ))}
                {/* <InfiniteLine mod="" /> */}
                {/* <Ecology /> */}
                {/* {view && view['15_minut'] && <Cites />} */}
                {/* <InfiniteLine mod="_2" /> */}
                {/* {view && view['instrukczii'] && <Instructions />} */}
            </main>
        </>
    );
};

export default Home;
