import React, { useEffect } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './NewsList.css';
import { newsSelector } from '../../store/ducks/news/selectors/newsSelector';
import { getNews, getNewsIdle } from '../../store/ducks/news/actions';
import { NewsSelectorOmitType } from '../../store/ducks/newsOne/types';
import {NewsState} from "../../store/ducks/news/types";

export function NewsList() {
    const dispatch = useDispatch();
    const status = useSelector((state: { news: NewsState }) => state.news.status);

    const { news }: { news: NewsSelectorOmitType[] } = useSelector(
        newsSelector
    );

    useEffect(() => {
        if (status === 'SUCCESS') {
            dispatch(getNewsIdle());
        }
    }, [status]);

    useEffect(() => {
        if (status === 'IDLE') {
            dispatch(getNews({limit: 3, page: 1}));
        }
    }, []);

    return (
        <>
            <ul className="news-list news__news-list">
                {news.map(n => {
                    return (
                        <li className="news-list__item" key={n.id}>
                            <Link to={n.slug} className="news-list__link">
                                <span className="news-list__data">
                                    {n.date}
                                </span>
                                <h3 className="news-list__title">{n.title}</h3>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
