import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import './HomeFirstScreen.css';
import { homeFirstScreenSelector } from '../../store/ducks/homepage/selectors/homepageSelector';
import { Link } from 'react-router-dom';
import { Loading } from '../UI/Loading/Loading';
import { PostFirstScreenSelectorType } from '../../store/ducks/homepage/types';
import { StateStatus } from '../../types/models';
import { State } from 'types';
import cn from 'classnames';
export function HomeFirstScreen() {
    const { post }: { post: PostFirstScreenSelectorType } = useSelector(
        homeFirstScreenSelector
    );

    const status = useSelector(
        (state: { homepage: { status: StateStatus } }) => state.homepage.status
    );

    if (!post || status === 'LOADING') return <Loading />;

    return (
        <>
            <section className="first-screen page__first-screen">
                <div className="first-screen__container">
                    <Link
                        to={post.pathTo}
                        className={cn(
                            'card',
                            'card--first-screen',
                            post.additional.smallPicture &&
                                'card--first-screen-bg',
                            'first-screen__card'
                        )}
                    >
                        <div className="card__img-ibg">
                            <img src={post.previewImg ? post.previewImg : post.image} alt="img" />
                        </div>
                        <div className="card__inner">
                            <span className="card__tag">{post.categories}</span>
                            <h3 className="card__title">
                                {post.additional.htmlTitle || post.title}
                            </h3>
                        </div>
                    </Link>
                </div>
            </section>
        </>
    );
}
