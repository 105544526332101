import React, { useEffect, useState } from 'react';
import './NewsCards.css';
import { useSelector } from 'react-redux';
import { homepageSelector } from '../../store/ducks/homepage/selectors/homepageSelector';
import { Link } from 'react-router-dom';
import { NewsList } from '../NewsList/NewsList';
import { PostsHomePageSelectorType } from '../../store/ducks/homepage/types';
import { Mailing } from '../Mailing/Mailing';

export function NewsCards() {
    const [browser, setBrowser] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (window.innerWidth > 1024) {
                setBrowser(false);
            } else {
                setBrowser(true);
            }
            const resizeThrottler = () => {
                if (window.innerWidth < 1024) {
                    setBrowser(true);
                } else {
                    setBrowser(false);
                }
            };

            window.addEventListener('resize', resizeThrottler, false);
        }
    }, []);

    const {
        cards: posts,
    }: { cards: PostsHomePageSelectorType[] } = useSelector(homepageSelector);

    console.log('posts: ', posts);

    return (
        <>
            <div className="news-cards news__news-cards">
                <div className="news-cards__wrapper">
                    {posts.map((post, index) => {
                        console.log('index: ', index);
                        let newsContent = (
                            <>
                                {browser && (
                                    <>
                                        <div className="news__wrap">
                                            <h2 className="news__title">
                                                Новости
                                            </h2>
                                            <NewsList />
                                        </div>
                                        <div></div>
                                        <div></div>
                                    </>
                                )}
                            </>
                        );
                        const lastItem = posts.length - 1 === index;

                        return (
                            <React.Fragment key={index}>
                                {index === 0 ? (
                                    <>
                                        <Link
                                            to={post.pathTo}
                                            className="card"
                                            key={post.id}
                                        >
                                            <div className="card__img-ibg">
                                                <img
                                                    src={
                                                        post.previewImg
                                                            ? post.previewImg
                                                            : post.image
                                                    }
                                                    alt="img"
                                                />
                                            </div>
                                            <div className="card__inner">
                                                <span className="card__tag">
                                                    {post.categories}
                                                </span>
                                                <h3
                                                    className="card__title"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            post.additional
                                                                .htmlTitle ||
                                                            post.title,
                                                    }}
                                                ></h3>
                                                <div
                                                    className="card__text"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            post.description,
                                                    }}
                                                ></div>
                                            </div>
                                        </Link>
                                        {browser && newsContent}
                                    </>
                                ) : index === 1 ? (
                                    <>
                                        <Link
                                            to={post.pathTo}
                                            className="card"
                                            key={post.id}
                                        >
                                            <div className="card__img-ibg">
                                                <img
                                                    src={
                                                        post.previewImg
                                                            ? post.previewImg
                                                            : post.image
                                                    }
                                                    alt="img"
                                                />
                                            </div>
                                            <div className="card__inner">
                                                <span className="card__tag">
                                                    {post.categories}
                                                </span>
                                                <h3
                                                    className="card__title"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            post.additional
                                                                .htmlTitle ||
                                                            post.title,
                                                    }}
                                                ></h3>
                                                <div
                                                    className="card__text"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            post.description,
                                                    }}
                                                ></div>
                                            </div>
                                        </Link>
                                        <div className="card">
                                            <Mailing />
                                        </div>
                                    </>
                                ) : (
                                    <Link
                                        to={post.pathTo}
                                        className="card"
                                        style={{ marginRight: lastItem && '0' }}
                                        key={post.id}
                                    >
                                        <div className="card__img-ibg">
                                            <img
                                                src={
                                                    post.previewImg
                                                        ? post.previewImg
                                                        : post.image
                                                }
                                                alt="img"
                                            />
                                        </div>
                                        <div className="card__inner">
                                            <span className="card__tag">
                                                {post.categories}
                                            </span>
                                            <h3
                                                className="card__title"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        post.additional
                                                            .htmlTitle ||
                                                        post.title,
                                                }}
                                            ></h3>
                                            <div
                                                className="card__text"
                                                dangerouslySetInnerHTML={{
                                                    __html: post.description,
                                                }}
                                            ></div>
                                        </div>
                                    </Link>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
