import React from 'react';
import { Link } from 'react-router-dom';

export function Loading() {
    return (
        <div className="wrapper _loader">
            <div className="loader">
                <div className="loader__header">
                    <Link to="/" className="logo logo-ibg header__logo">
                        <img src="/img/logo.svg" alt="logo" />
                    </Link>
                    <Link to="/" className="logo-icon logo-ibg header__logo">
                        <img src="/img/logo2.svg" alt="logo" />
                    </Link>
                </div>
                <div className="loader__main">
                    <div className="infinite-line">
                        <div className="infinite-line__block">
                            <p>Загрузка</p>
                            <p>Загрузка</p>
                            <p>Загрузка</p>
                            <p>Загрузка</p>
                            <p>Загрузка</p>
                            <p>Загрузка</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
