import { createSelector } from 'reselect';
import {
    getFullPath,
    removeAllHTMLAndUnicodeSymbols,
} from '../../../helpers/helpers';
import { displayDate } from '../../../helpers/displayDate.helpers';
import { NewsReduxType, NewsSelectorOmitType } from '../../newsOne/types';
import {isAuthorNull} from "../../../helpers/isAuthorNull.helpers";

const news = (state: { news: { news: NewsReduxType[] } }) => state.news.news;

export const newsSelector = createSelector([news], news => {
    let renderNews: NewsSelectorOmitType[] = [];

    if (news) {
        news.map((n: NewsReduxType) => {
            let tag = '',
                object = {
                    id: n.id,
                    date: displayDate(n.date),
                    title: n.additional.htmlTitle || n.title.rendered,
                    tag: n.tag || tag,
                    type: n.type,
                    slug: getFullPath(['news', n.slug]),
                    cover: {author: isAuthorNull(n.cover.author)},
                    content: n.content.rendered,
                    dataSlug: n.slug,
                    image: n.images.large || ''
                };

            renderNews.push(object);
        });
    }
    return { news: renderNews };
});
