import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import cn from 'classnames';
import styled, { keyframes } from 'styled-components';

import { ViewReduxType } from '../../store/ducks/homepage/types';

import './InfiniteLine.css';


export function InfiniteLine(props: any) {
    const { mod, title } = props;

    const [widthP, setWidthP] = useState(0);

    const { view } = useSelector(
        (state: { homepage: { view: ViewReduxType } }) => state.homepage
    );

    useEffect(() => {}, []);

    // const infBlock = useRef(null);
    const infP = useRef(null);

    // useEffect(() => {
    //     console.log(
    //         'width block',
    //         infBlock.current ? infBlock.current.offsetWidth : 0
    //     );
    // }, [infBlock.current]);

    useEffect(() => {
        infP.current ? setWidthP(infP.current.offsetWidth) : 0;
    }, [infP.current]);

    const animate = keyframes`
    0% {
        transform: translateX(0);
      }
      to {
        transform: translateX(-${widthP + 170}px);
      }
`;

    const InfiniteBlock = styled.div`
        animation: ${animate} 7s linear infinite;
    `;

    return (
        <>
            <div className={cn('infinite-line', mod === '_2' && '_2')}>
                <InfiniteBlock
                    className={cn('infinite-line__block', mod === '_2' && '_2')}
                >
                    <p ref={infP}>{title}</p>
                    <p>{title}</p>
                    <p>{title}</p>
                    <p>{title}</p>
                    <p>{title}</p>
                </InfiniteBlock>
            </div>
        </>
    );
}
