import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { InfiniteLine } from 'components/InfiniteLine/InfiniteLine';

import { compilationSelector } from '../../store/ducks/homepage/selectors/homepageSelector';
import { PostsCompilationSelectorType } from '../../store/ducks/homepage/types';
import { getCompilation } from 'store/ducks/homepage/actions';

import './Compilation.css';

export function Compilation(props: any) {
    const { content, posts } = props;

    const dispatch = useDispatch();

    useEffect(() => {
        if (content) {
            dispatch(
                getCompilation({
                    tag: content.compilation_tag,
                    count: content.compilation_number,
                })
            );
        }
    }, []);

    const {
        compilation: compilationPosts,
    }: { compilation: PostsCompilationSelectorType[] } = useSelector(
        compilationSelector
    );

    return (
        <>
            {content && content.compilation_selects === 'purple' && (
                <>
                    <InfiniteLine mod="" title={content.compilation_title} />
                    <section className="ecology page__ecology _purple">
                        <div className="ecology__container">
                            <div className="ecology__content">
                                {compilationPosts.map((ecoPost: any) => {
                                    return (
                                        <Link
                                            to={ecoPost.pathTo}
                                            className="card"
                                            key={ecoPost.id}
                                        >
                                            <div className="card__img-ibg">
                                                <img
                                                    src={ecoPost.previewImg ? ecoPost.previewImg : ecoPost.image}
                                                    alt="img"
                                                />
                                            </div>
                                            <div className="card__inner">
                                                <span className="card__tag">
                                                    {ecoPost.categories}
                                                </span>
                                                <h3 className="card__title">
                                                    {ecoPost.title}
                                                </h3>
                                            </div>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                </>
            )}
            {content && content.compilation_selects === 'yellow' && (
                <>
                    {' '}
                    <InfiniteLine mod="_2" title={content.compilation_title} />
                    <section className="instructions page__instructions">
                        <div className="instructions__container">
                            <div className="instructions__list">
                                {compilationPosts.map((el: any) => {
                                    return (
                                        <Link
                                            to={el.pathTo}
                                            className="instructions__items"
                                            key={el.id}
                                        >
                                            <div className="instructions__img-ibg">
                                                <img src={el.previewImg ? el.previewImg : el.image} alt="img" />
                                            </div>
                                            <h3 className="instructions__title">
                                                {el.title}
                                            </h3>
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                </>
            )}
        </>
    );
}
