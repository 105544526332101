import React, { useState, useEffect } from 'react';

import { useForm, SubmitHandler } from 'react-hook-form';
import cn from 'classnames';
import fetch from 'isomorphic-fetch';
import './Mailing.css';

const isValidEmail = (email:any) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

type Props = {
    email: string;
    check: string;
};

export function Mailing(props: Props) {
    const {
        register,
        handleSubmit,
        watch,
        setError,
        formState: { errors },
    } = useForm<Props>();
    const onSubmit: SubmitHandler<Props> = dataList => {
        console.log(dataList);

        const url = "https://ryadom.media/wp/wp-admin/admin-ajax.php";

        let data = new FormData();
        data.append('action', 'add_subscribe');
        data.append('email',  dataList.email);

        const options = {
          method: "POST",
          body: data
        };
        fetch(url, options)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            setFormDisabled(data.status === "ok" ? true : false);
          })
          .catch(error => console.log('error', error));


    };
    const [formDisabled, setFormDisabled] = useState(false);



    const handleEmailValidation = (email: any) => {
        console.log("ValidateEmail was called with", email);
    
        const isValid = isValidEmail(email);
    
        const validityChanged =
          (errors.email && isValid) || (!errors.email && !isValid);
        if (validityChanged) {
          console.log("Fire tracker with", isValid ? "Valid" : "Invalid");
        }
    
        return isValid;
      };

    useEffect(() => {
        console.log(errors);
    }, [errors]);

    return (
        <>
            <div className={cn("mailing", formDisabled && "_success")}>
                {!formDisabled && (
                    <>
                        <div className="mailing__title">
                            Подпишитесь на медиа «Рядом»
                        </div>
                        <form
                            action="#"
                            className="mailing__form"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <input
                                className={cn("mailing__input", errors.email && "_error")}
                                type="text"
                                placeholder="E-mail"
                                name="email"
                                {...register('email', { required: true, validate: handleEmailValidation })}
                            />
                            <button className="mailing__btn" type="submit">
                                Отправить
                            </button>
                            <label className="mailing__label">
                                <input
                                    type="checkbox"
                                    className={cn("mailing__checkbox", errors.check && "_error")}
                                    {...register('check', { required: true })}
                                />
                                <div className="mailing__checkbox-text">
                                    я согласен на <a href="https://etalongroup.ru/upload/politika-etalon-2021.pdf" target="_blank" >обработку персональных данных</a>
                                </div>
                            </label>
                        </form>
                    </>
                )}
                {formDisabled && (
                    <div className="mailing__title">
                        Спасибо, что подписались! Скоро мы вам напишем
                    </div>
                )}
            </div>
        </>
    );
}
